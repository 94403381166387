/* SAMPLE COLLECTORS */
// get TYPE
export const GET_TYPE_LIST = "GET_TYPE_LIST";
export const GET_TYPE_LIST_SUCCESS ="GET_TYPE_LIST_SUCCESS";
export const GET_TYPE_LIST_FAIL = "GET_TYPE_LIST_FAIL";
// Add TYPE
export const ADD_NEW_TYPE = "ADD_NEW_TYPE";
export const ADD_NEW_TYPE_SUCCESS = "ADD_NEW_TYPE_SUCCESS";
export const ADD_NEW_TYPE_FAIL = "ADD_NEW_TYPE_FAIL";
//Update TYPE
export const UPDATE_TYPE = "UPDATE_TYPE";
export const UPDATE_TYPE_SUCCESS = "UPDATE_TYPE_SUCCESS";
export const UPDATE_TYPE_FAIL = "UPDATE_TYPE_FAIL";


