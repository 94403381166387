
// get REAGENT
export const GET_REAGENTS_LIST = "GET_REAGENTS_LIST";
export const GET_REAGENTS_LIST_SUCCESS ="GET_REAGENTS_LIST_SUCCESS";
export const GET_REAGENTS_LIST_FAIL = "GET_REAGENTS_LIST_FAIL";
// Add REAGENT
export const ADD_NEW_REAGENTS = "ADD_NEW_REAGENTS";
export const ADD_NEW_REAGENTS_SUCCESS = "ADD_NEW_REAGENTS_SUCCESS";
export const ADD_NEW_REAGENTS_FAIL = "ADD_NEW_REAGENTS_FAIL";
//Update REAGENT
export const UPDATE_REAGENTS = "UPDATE_REAGENTS";
export const UPDATE_REAGENTS_SUCCESS = "UPDATE_REAGENTS_SUCCESS";
export const UPDATE_REAGENTS_FAIL = "UPDATE_REAGENTS_FAIL";

//delete REAGENT
export const DELETE_REAGENT = "DELETE_REAGENT";
export const DELETE_REAGENT_SUCCESS = "DELETE_REAGENT_SUCCESS";
export const DELETE_REAGENT_FAIL = "DELETE_REAGENT_FAIL";



/////reagents added by analytes

export const GET_ANALYTESREAGENTS_LIST = "GET_ANALYTESREAGENTS_LIST_LIST";
export const GET_ANALYTESREAGENTS_LIST_SUCCESS ="GET_ANALYTESREAGENTS_LIST_SUCCESS";
export const GET_ANALYTESREAGENTS_LIST_FAIL = "GET_ANALYTESREAGENTS_LIST_FAIL";
// Add REAGENT
export const ADD_NEW_ANALYTESREAGENTS = "ADD_NEW_ANALYTESREAGENTS";
export const ADD_NEW_ANALYTESREAGENTS_SUCCESS = "ADD_NEW_ANALYTESREAGENTS_SUCCESS";
export const ADD_NEW_ANALYTESREAGENTS_FAIL = "ADD_NEW_ANALYTESREAGENTS_FAIL";
//Update REAGENT
export const UPDATE_ANALYTESREAGENTS = "UPDATE_ANALYTESREAGENTS";
export const UPDATE_ANALYTESREAGENTS_SUCCESS = "UPDATE_ANALYTESREAGENTS_SUCCESS";
export const UPDATE_ANALYTESREAGENTS_FAIL = "UPDATE_ANALYTESREAGENTS_FAIL";

