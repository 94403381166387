
/* Cycle List */
export const GET_CYCLE_LIST = "GET_CYCLE_LIST";
export const GET_CYCLE_LIST_SUCCESS = "GET_CYCLE_LIST_SUCCESS";
export const GET_CYCLE_LIST_FAIL = "GET_CYCLE_LIST_FAIL";


///Ading New Cycle
export const ADD_NEW_CYCLE_LIST = "ADD_NEW_CYCLE_LIST";
export const ADD_NEW_CYCLE_LIST_SUCCESS = "ADD_NEW_CYCLE_LIST_SUCCESS";
export const ADD_NEW_CYCLE_LIST_FAIL = "ADD_NEW_CYCLE_LIST_FAIL";

// Update Cycle
export const UPDATE_NEW_CYCLE_LIST = "UPDATE_NEW_CYCLE_LIST";
export const UPDATE_NEW_CYCLE_LIST_SUCCESS = "UPDATE_NEW_CYCLE_LIST_SUCCESS";
export const UPDATE_NEW_CYCLE_LIST_FAIL = "UPDATE_NEW_CYCLE_LIST_FAIL";

// Delete Cycle
export const DELETE_CYCLE = "DELETE_CYCLE";
export const DELETE_CYCLE_SUCCESS = "DELETE_CYCLE_SUCCESS";
export const DELETE_CYCLE_FAIL = "DELETE_CYCLE_FAIL";
