
export const GET_RESULT_SUBMIT = "GET_RESULT_SUBMIT";
export const GET_RESULT_SUBMIT_SUCCESS = "GET_RESULT_SUBMIT_SUCCESS";
export const GET_RESULT_SUBMIT_FAIL = "GET_RESULT_SUBMIT_FAIL";

export const GET_REPORT = "GET_REPORT";
export const GET_REPORT_SUCCESS = "GET_REPORT_SUCCESS";
export const GET_REPORT_FAIL = "GET_REPORT_FAIL";

export const GET_SERELOGY_RESULT = "GET_SERELOGY_RESULT";
export const GET_SERELOGY_RESULT_SUCCESS = "GET_SERELOGY_RESULT_SUCCESS";
export const GET_SERELOGY_RESULT_FAIL = "GET_SERELOGY_RESULT_FAIL";

