/* SAMPLE COLLECTORS */
// get CITY
export const GET_CITY_LIST = "GET_CITY_LIST";
export const GET_CITY_LIST_SUCCESS ="GET_CITY_LIST_SUCCESS";
export const GET_CITY_LIST_FAIL = "GET_CITY_LIST_FAIL";
// Add CITY
export const ADD_NEW_CITY = "ADD_NEW_CITY";
export const ADD_NEW_CITY_SUCCESS = "ADD_NEW_CITY_SUCCESS";
export const ADD_NEW_CITY_FAIL = "ADD_NEW_CITY_FAIL";
//Update CITY
export const UPDATE_CITY = "UPDATE_CITY";
export const UPDATE_CITY_SUCCESS = "UPDATE_CITY_SUCCESS";
export const UPDATE_CITY_FAIL = "UPDATE_CITY_FAIL";


