/*  PATIENT TEST APPOINTMENTtS */

export const GET_LABS_LIST =
  "GET_LABS_LIST";
export const GET_LABS_LIST_SUCCESS =
  "GET_LABS_LIST_SUCCESS";
export const GET_LABS_LIST_FAIL =
  "GET_LABS_LIST_FAIL";
  /////////////
  export const GET_LC_LIST =
  "GET_LC_LIST";
export const GET_LC_LIST_SUCCESS =
  "GET_LC_LIST_SUCCESS";
export const GET_LC_LIST_FAIL =
  "GET_LC_LIST_FAIL";
  /////////////////////

  