/* INSTRUMENT List */
export const GET_INSTRUMENT_LIST = "GET_INSTRUMENT_LIST";
export const GET_INSTRUMENT_LIST_SUCCESS =
  "GET_INSTRUMENT_LIST_SUCCESS";
export const GET_INSTRUMENT_LIST_FAIL = "GET_INSTRUMENT_LIST_FAIL";

///adding new instrument
export const ADD_NEW_INSTRUMENT = "ADD_NEW_INSTRUMENT";
export const ADD_NEW_INSTRUMENT_SUCCESS = "ADD_NEW_INSTRUMENT_SUCCESS";
export const ADD_NEW_INSTRUMENT_FAIL = "ADD_NEW_INSTRUMENT_FAIL";

//update INSTRUMENT
export const UPDATE_INSTRUMENT = "UPDATE_INSTRUMENT";
export const UPDATE_INSTRUMENT_SUCCESS = "UPDATE_INSTRUMENT_SUCCESS";
export const UPDATE_INSTRUMENT_FAIL = "UPDATE_INSTRUMENT_FAIL";

//delete INSTRUMENT
export const DELETE_INSTRUMENT = "DELETE_INSTRUMENT";
export const DELETE_INSTRUMENT_SUCCESS = "DELETE_INSTRUMENT_SUCCESS";
export const DELETE_INSTRUMENT_FAIL = "DELETE_INSTRUMENT_FAIL";


/////Equipments added by analytes

export const GET_ANALYTESEQUIPMENTS_LIST = "GET_ANALYTESEQUIPMENTS_LIST_LIST";
export const GET_ANALYTESEQUIPMENTS_LIST_SUCCESS ="GET_ANALYTESEQUIPMENTS_LIST_SUCCESS";
export const GET_ANALYTESEQUIPMENTS_LIST_FAIL = "GET_ANALYTESEQUIPMENTS_LIST_FAIL";
// Add units
export const ADD_NEW_ANALYTESEQUIPMENTS = "ADD_NEW_ANALYTESEQUIPMENTS";
export const ADD_NEW_ANALYTESEQUIPMENTS_SUCCESS = "ADD_NEW_ANALYTESEQUIPMENTS_SUCCESS";
export const ADD_NEW_ANALYTESEQUIPMENTS_FAIL = "ADD_NEW_ANALYTESEQUIPMENTS_FAIL";
//Update Units
export const UPDATE_ANALYTESEQUIPMENTS = "UPDATE_ANALYTESEQUIPMENTS";
export const UPDATE_ANALYTESEQUIPMENTS_SUCCESS = "UPDATE_ANALYTESEQUIPMENTS_SUCCESS";
export const UPDATE_ANALYTESEQUIPMENTS_FAIL = "UPDATE_ANALYTESEQUIPMENTS_FAIL";