
/* Round List */
export const GET_ROUND_LIST = "GET_ROUND_LIST";
export const GET_ROUND_LIST_SUCCESS = "GET_ROUND_LIST_SUCCESS";
export const GET_ROUND_LIST_FAIL = "GET_ROUND_LIST_FAIL";


///Ading New Round
export const ADD_NEW_ROUND_LIST = "ADD_NEW_ROUND_LIST";
export const ADD_NEW_ROUND_LIST_SUCCESS = "ADD_NEW_ROUND_LIST_SUCCESS";
export const ADD_NEW_ROUND_LIST_FAIL = "ADD_NEW_ROUND_LIST_FAIL";

// Update Round
export const UPDATE_NEW_ROUND_LIST = "UPDATE_NEW_ROUND_LIST";
export const UPDATE_NEW_ROUND_LIST_SUCCESS = "UPDATE_NEW_ROUND_LIST_SUCCESS";
export const UPDATE_NEW_ROUND_LIST_FAIL = "UPDATE_NEW_ROUND_LIST_FAIL";

export const DELETE_ROUND = "DELETE_ROUND";
export const DELETE_ROUND_SUCCESS = "DELETE_ROUND_SUCCESS";
export const DELETE_ROUND_FAIL = "DELETE_ROUND_FAIL";
