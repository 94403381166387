/* Instrument types */
export const GET_INSTRUMENT_TYPE_LIST = "GET_INSTRUMENT_TYPE_LIST";
export const GET_INSTRUMENT_TYPE_LIST_SUCCESS = "GET_INSTRUMENT_TYPE_LIST_SUCCESS";
export const GET_INSTRUMENT_TYPE_LIST_FAIL = "GET_INSTRUMENT_TYPE_LIST_FAIL";

///adding new instrument type
export const ADD_NEW_INSTRUMENT_TYPE = "ADD_NEW_INSTRUMENT_TYPE";
export const ADD_NEW_INSTRUMENT_TYPE_SUCCESS = "ADD_NEW_INSTRUMENT_TYPE_SUCCESS";
export const ADD_NEW_INSTRUMENT_TYPE_FAIL = "ADD_NEW_INSTRUMENT_TYPE_FAIL";

///adding new equi type
export const ADD_EQUIPMENTTYPE_FILE = "ADD_EQUIPMENTTYPE_FILE";
export const ADD_EQUIPMENTTYPE_FILE_SUCCESS = "ADD_EQUIPMENTTYPE_FILE_SUCCESS";
export const ADD_EQUIPMENTTYPE_FILE_FAIL = "ADD_EQUIPMENTTYPE_FILE_FAIL";

//update instrument type
export const UPDATE_NEW_INSTRUMENT_TYPE = "UPDATE_NEW_INSTRUMENT_TYPE";
export const UPDATE_NEW_INSTRUMENT_TYPE_SUCCESS = "UPDATE_NEW_INSTRUMENT_TYPE_SUCCESS";
export const UPDATE_NEW_INSTRUMENT_TYPE_FAIL = "UPDATE_NEW_INSTRUMENT_TYPE_FAIL";

//delete instrument type
export const DELETE_INSTRUMENT_TYPE = "DELETE_INSTRUMENT_TYPE";
export const DELETE_INSTRUMENT_TYPE_SUCCESS = "DELETE_INSTRUMENT_TYPE_SUCCESS";
export const DELETE_INSTRUMENT_TYPE_FAIL = "DELETE_INSTRUMENT_TYPE_FAIL";

/* Analyte List */
export const GET_ANALYTE_LIST = "GET_ANALYTE_LIST";
export const GET_ANALYTE_LIST_SUCCESS = "GET_ANALYTE_LIST_SUCCESS";
export const GET_ANALYTE_LIST_FAIL = "GET_ANALYTE_LIST_FAIL";

/* Analyte For Scheme */
export const GET_ANALYTEFORSCHEME_LIST = "GET_ANALYTEFORSCHEME_LIST";
export const GET_ANALYTEFORSCHEME_LIST_SUCCESS = "GET_ANALYTEFORSCHEME_LIST_SUCCESS";
export const GET_ANALYTEFORSCHEME_LIST_FAIL = "GET_ANALYTEFORSCHEME_LIST_FAIL";

///adding new Analyte
export const ADD_NEW_ANALYTE_LIST = "ADD_NEW_ANALYTE_LIST";
export const ADD_NEW_ANALYTE_LIST_SUCCESS = "ADD_NEW_ANALYTE_LIST_SUCCESS";
export const ADD_NEW_ANALYTE_LIST_FAIL = "ADD_NEW_ANALYTE_LIST_FAIL";

//update Analyte
export const UPDATE_NEW_ANALYTE_LIST = "UPDATE_NEW_ANALYTE_LIST";
export const UPDATE_NEW_ANALYTE_LIST_SUCCESS = "UPDATE_NEW_ANALYTE_LIST_SUCCESS";
export const UPDATE_NEW_ANALYTE_LIST_FAIL = "UPDATE_NEW_ANALYTE_LIST_FAIL";

//delete ANALYTE
export const DELETE_ANALYTE = "DELETE_ANALYTE";
export const DELETE_ANALYTE_SUCCESS = "DELETE_ANALYTE_SUCCESS";
export const DELETE_ANALYTE_FAIL = "DELETE_ANALYTE_FAIL";




///// Get Analytes added by Scheme page

export const GET_SCHEMEANALYTE_LIST = "GET_SCHEMEANALYTE_LIST_LIST";
export const GET_SCHEMEANALYTE_LIST_SUCCESS ="GET_SCHEMEANALYTE_LIST_SUCCESS";
export const GET_SCHEMEANALYTE_LIST_FAIL = "GET_SCHEMEANALYTE_LIST_FAIL";
// Add Analytes added by Scheme page
export const ADD_NEW_SCHEMEANALYTE = "ADD_NEW_SCHEMEANALYTE";
export const ADD_NEW_SCHEMEANALYTE_SUCCESS = "ADD_NEW_SCHEMEANALYTE_SUCCESS";
export const ADD_NEW_SCHEMEANALYTE_FAIL = "ADD_NEW_SCHEMEANALYTE_FAIL";
//Update Analytes added by Scheme page
export const UPDATE_SCHEMEANALYTE = "UPDATE_SCHEMEANALYTE";
export const UPDATE_SCHEMEANALYTE_SUCCESS = "UPDATE_SCHEMEANALYTE_SUCCESS";
export const UPDATE_SCHEMEANALYTE_FAIL = "UPDATE_SCHEMEANALYTE_FAIL";

/// Get Analytes Associated With Cycle
export const GET_ANALYTESCYCLES = "GET_ANALYTESCYCLES_LIST";
export const GET_ANALYTESCYCLES_SUCCESS ="GET_ANALYTESCYCLES_SUCCESS";
export const GET_ANALYTESCYCLES_FAIL = "GET_ANALYTESCYCLES_FAIL";


///// Get Analytes added by Sample page
export const GET_SAMPLE_ANALYTE_LIST = "GET_SAMPLE_ANALYTE_LIST";
export const GET_SAMPLE_ANALYTE_LIST_SUCCESS ="GET_SAMPLE_ANALYTE_LIST_SUCCESS";
export const GET_SAMPLE_ANALYTE_LIST_FAIL = "GET_SAMPLE_ANALYTE_LIST_FAIL";
// Add Analytes added by Sample page
export const ADD_NEW_SAMPLE_ANALYTE = "ADD_NEW_SAMPLE_ANALYTE";
export const ADD_NEW_SAMPLE_ANALYTE_SUCCESS = "ADD_NEW_SAMPLE_ANALYTE_SUCCESS";
export const ADD_NEW_SAMPLE_ANALYTE_FAIL = "ADD_NEW_SAMPLE_ANALYTE_FAIL";
//Update Analytes added by Sample page
export const UPDATE_SAMPLE_ANALYTE = "UPDATE_SAMPLE_ANALYTE";
export const UPDATE_SAMPLE_ANALYTE_SUCCESS = "UPDATE_SAMPLE_ANALYTE_SUCCESS";
export const UPDATE_SAMPLE_ANALYTE_FAIL = "UPDATE_SAMPLE_ANALYTE_FAIL";