/* SAMPLE COLLECTORS */
// get PROVINCE
export const GET_PROVINCE_LIST = "GET_PROVINCE_LIST";
export const GET_PROVINCE_LIST_SUCCESS ="GET_PROVINCE_LIST_SUCCESS";
export const GET_PROVINCE_LIST_FAIL = "GET_PROVINCE_LIST_FAIL";
// Add PROVINCE
export const ADD_NEW_PROVINCE = "ADD_NEW_PROVINCE";
export const ADD_NEW_PROVINCE_SUCCESS = "ADD_NEW_PROVINCE_SUCCESS";
export const ADD_NEW_PROVINCE_FAIL = "ADD_NEW_PROVINCE_FAIL";
//Update PROVINCE
export const UPDATE_PROVINCE = "UPDATE_PROVINCE";
export const UPDATE_PROVINCE_SUCCESS = "UPDATE_PROVINCE_SUCCESS";
export const UPDATE_PROVINCE_FAIL = "UPDATE_PROVINCE_FAIL";


