
// -------------------- LAB ACTION TYPES --------------------
export const GET_PENDING_LABS = "GET_PENDING_LABS";
export const GET_PENDING_LABS_SUCCESS = "GET_PENDING_LABS_SUCCESS";
export const GET_PENDING_LABS_FAIL = "GET_PENDING_LABS_FAIL";

export const GET_APPROVED_LABS = "GET_APPROVED_LABS";
export const GET_APPROVED_LABS_SUCCESS = "GET_APPROVED_LABS_SUCCESS";
export const GET_APPROVED_LABS_FAIL = "GET_APPROVED_LABS_FAIL";

export const GET_UNAPPROVED_LABS = "GET_UNAPPROVED_LABS";
export const GET_UNAPPROVED_LABS_SUCCESS = "GET_UNAPPROVED_LABS_SUCCESS";
export const GET_UNAPPROVED_LABS_FAIL = "GET_UNAPPROVED_LABS_FAIL";

export const APPROVE_UNAPPROVE_LAB = "APPROVE_UNAPPROVE_LAB";
export const APPROVE_UNAPPROVE_LAB_SUCCESS = "APPROVE_UNAPPROVE_LAB_SUCCESS";
export const APPROVE_UNAPPROVE_LAB_FAIL = "APPROVE_UNAPPROVE_LAB_FAIL";

export const GET_ALL_PARTICIPANT = "GET_ALL_PARTICIPANT";
export const GET_ALL_PARTICIPANT_SUCCESS = "GET_ALL_PARTICIPANT_SUCCESS";
export const GET_ALL_PARTICIPANT_FAIL = "GET_ALL_PARTICIPANT_FAIL";

// -------------------- B2B ACTION TYPES --------------------
export const GET_PENDING_B2B_CLIENTS = "GET_PENDING_B2B_CLIENTS";
export const GET_PENDING_B2B_CLIENTS_SUCCESS =
  "GET_PENDING_B2B_CLIENTS_SUCCESS";
export const GET_PENDING_B2B_CLIENTS_FAIL = "GET_PENDING_B2B_CLIENTS_FAIL";

export const GET_APPROVED_B2B_CLIENTS = "GET_APPROVED_B2B_CLIENTS";
export const GET_APPROVED_B2B_CLIENTS_SUCCESS =
  "GET_APPROVED_B2B_CLIENTS_SUCCESS";
export const GET_APPROVED_B2B_CLIENTS_FAIL = "GET_APPROVED_B2B_CLIENTS_FAIL";

export const GET_UNAPPROVED_B2B_CLIENTS = "GET_UNAPPROVED_B2B_CLIENTS";
export const GET_UNAPPROVED_B2B_CLIENTS_SUCCESS =
  "GET_UNAPPROVED_B2B_CLIENTS_SUCCESS";
export const GET_UNAPPROVED_B2B_CLIENTS_FAIL =
  "GET_UNAPPROVED_B2B_CLIENTS_FAIL";

export const APPROVE_UNAPPROVE_B2B_CLIENT = "APPROVE_UNAPPROVE_B2B_CLIENT";
export const APPROVE_UNAPPROVE_B2B_CLIENT_SUCCESS =
  "APPROVE_UNAPPROVE_B2B_CLIENT_SUCCESS";
export const APPROVE_UNAPPROVE_B2B_CLIENT_FAIL =
  "APPROVE_UNAPPROVE_B2B_CLIENT_FAIL";

export const UPDATE_MEMBERSHIP_STATUS = "UPDATE_MEMBERSHIP_STATUS";
export const UPDATE_MEMBERSHIP_STATUS_SUCCESS =
  "UPDATE_MEMBERSHIP_STATUS_SUCCESS";
export const UPDATE_MEMBERSHIP_STATUS_FAIL =
  "UPDATE_MEMBERSHIP_STATUS_FAIL";

