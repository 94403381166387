/* PRODUCTS */
export const GET_NEARBY_LABS = "GET_NEARBY_LABS";
export const GET_NEARBY_LABS_SUCCESS = "GET_NEARBY_LABS_SUCCESS";
export const GET_NEARBY_LABS_FAIL = "GET_NEARBY_LABS_FAIL";


export const GET_REGION_WISE_ADVERTISEMENT = "REGION_WISE_ADVERTISEMENT";
export const GET_REGION_WISE_ADVERTISEMENT_SUCCESS = "GET_REGION_WISE_ADVERTISEMENT_SUCCESS";
export const GET_REGION_WISE_ADVERTISEMENT_FAIL = "GET_REGION_WISE_ADVERTISEMENT_FAIL";

export const GET_ADV_LIVE = "GET_ADV_LIVE";
export const GET_ADV_LIVE_SUCCESS = "GET_ADV_LIVE_SUCCESS";
export const GET_ADV_LIVE_FAIL = "GET_ADV_LIVE_FAIL";
