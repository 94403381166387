/* SAMPLE COLLECTORS */
// get DISTRICT
export const GET_DISTRICT_LIST = "GET_DISTRICT_LIST";
export const GET_DISTRICT_LIST_SUCCESS ="GET_DISTRICT_LIST_SUCCESS";
export const GET_DISTRICT_LIST_FAIL = "GET_DISTRICT_LIST_FAIL";
// Add DISTRICT
export const ADD_NEW_DISTRICT = "ADD_NEW_DISTRICT";
export const ADD_NEW_DISTRICT_SUCCESS = "ADD_NEW_DISTRICT_SUCCESS";
export const ADD_NEW_DISTRICT_FAIL = "ADD_NEW_DISTRICT_FAIL";
//Update DISTRICT
export const UPDATE_DISTRICT = "UPDATE_DISTRICT";
export const UPDATE_DISTRICT_SUCCESS = "UPDATE_DISTRICT_SUCCESS";
export const UPDATE_DISTRICT_FAIL = "UPDATE_DISTRICT_FAIL";


