
/* Sample List */
export const GET_SAMPLE_LIST = "GET_SAMPLE_LIST";
export const GET_SAMPLE_LIST_SUCCESS ="GET_SAMPLE_LIST_SUCCESS";
export const GET_SAMPLE_LIST_FAIL = "GET_SAMPLE_LIST_FAIL";


// /adding new Sample
export const ADD_NEW_SAMPLE_LIST = "GET_NEW_SAMPLE_LIST";
export const ADD_NEW_SAMPLE_LIST_SUCCESS = "GET_NEW_SAMPLE_LIST_SUCCESS";
export const ADD_NEW_SAMPLE_LIST_FAIL = "GET_NEW_SAMPLE_LIST_FAIL";

// //update Sample
export const UPDATE_NEW_SAMPLE_LIST = "UPDATE_NEW_SAMPLE_LIST";
export const UPDATE_NEW_SAMPLE_LIST_SUCCESS = "UPDATE_NEW_SAMPLE_LIST_SUCCESS";
export const UPDATE_NEW_SAMPLE_LIST_FAIL = "UPDATE_NEW_SAMPLE_LIST_FAIL";

// // delete sample
export const DELETE_NEW_SAMPLE_LIST = "DELETE_NEW_SAMPLE_LIST";
export const DELETE_NEW_SAMPLE_LIST_SUCCESS = "DELETE_NEW_SAMPLE_LIST_SUCCESS";
export const DELETE_NEW_SAMPLE_LIST_FAIL = "DELETE_NEW_SAMPLE_LIST_FAIL";