/*  PATIENT TEST APPOINTMENTtS */

export const GET_LABS_LIST =
  "GET_LABS_LIST";
export const GET_LABS_LIST_SUCCESS =
  "GET_LABS_LIST_SUCCESS";
export const GET_LABS_LIST_FAIL =
  "GET_LABS_LIST_FAIL";


  