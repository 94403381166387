/* SAMPLE COLLECTORS */
// get DEPARTMENT
export const GET_DEPARTMENT_LIST = "GET_DEPARTMENT_LIST";
export const GET_DEPARTMENT_LIST_SUCCESS ="GET_DEPARTMENT_LIST_SUCCESS";
export const GET_DEPARTMENT_LIST_FAIL = "GET_DEPARTMENT_LIST_FAIL";
// Add DEPARTMENT
export const ADD_NEW_DEPARTMENT = "ADD_NEW_DEPARTMENT";
export const ADD_NEW_DEPARTMENT_SUCCESS = "ADD_NEW_DEPARTMENT_SUCCESS";
export const ADD_NEW_DEPARTMENT_FAIL = "ADD_NEW_DEPARTMENT_FAIL";
//Update DEPARTMENT
export const UPDATE_DEPARTMENT = "UPDATE_DEPARTMENT";
export const UPDATE_DEPARTMENT_SUCCESS = "UPDATE_DEPARTMENT_SUCCESS";
export const UPDATE_DEPARTMENT_FAIL = "UPDATE_DEPARTMENT_FAIL";


