export const GET_CSR_LIST = "GET_CSR_LIST";
export const GET_CSR_LIST_SUCCESS = "GET_CSR_LIST_SUCCESS";
export const GET_CSR_LIST_FAIL = "GET_CSR_LIST_FAIL";

export const GET_AUDITOR_LIST = "GET_AUDITOR_LIST";
export const GET_AUDITOR_LIST_SUCCESS = "GET_AUDITOR_LIST_SUCCESS";
export const GET_AUDITOR_LIST_FAIL = "GET_AUDITOR_LIST_FAIL";

export const GET_ORGANIZATION_LIST = "GET_ORGANIZATION_LIST";
export const GET_ORGANIZATION_LIST_SUCCESS = "GET_ORGANIZATION_LIST_SUCCESS";
export const GET_ORGANIZATION_LIST_FAIL = "GET_ORGANIZATION_LIST_FAIL";

export const GET_FINANCE_OFFICER_LIST = "GET_FINANCE_OFFICER_LIST";
export const GET_FINANCE_OFFICER_LIST_SUCCESS =
  "GET_FINANCE_OFFICER_LIST_SUCCESS";
export const GET_FINANCE_OFFICER_LIST_FAIL = "GET_FINANCE_OFFICER_LIST_FAIL";

// Territories
export const GET_TERRITORIES_LIST =
  "GET_TERRITORIES_LIST";
export const GET_TERRITORIES_LIST_SUCCESS =
  "GET_TERRITORIES_LIST_SUCCESS";
export const GET_TERRITORIES_LIST_FAIL =
  "GET_TERRITORIES_LIST_FAIL";

export const ADD_STAFF = "ADD_STAFF";
export const ADD_STAFF_SUCCESS = "ADD_STAFF_SUCCESS";
export const ADD_STAFF_FAIL = "ADD_STAFF_FAIL";

export const UPDATE_STAFF = "UPDATE_STAFF";
export const UPDATE_STAFF_SUCCESS = "UPDATE_STAFF_SUCCESS";
export const UPDATE_STAFF_FAIL = "UPDATE_STAFF_FAIL";

export const DELETE_STAFF = "DELETE_STAFF";
export const DELETE_STAFF_SUCCESS = "DELETE_STAFF_SUCCESS";
export const DELETE_STAFF_FAIL = "DELETE_STAFF_FAIL";