export const REGISTER_ORGANIZATION = "REGISTER_ORGANIZATION"
export const REGISTER_ORGANIZATION_SUCCESSFUL = "REGISTER_ORGANIZATION_SUCCESSFUL"
export const REGISTER_ORGANIZATION_FAILED = "REGISTER_ORGANIZATION_FAILED"

export const GET_ORGANIZATION_LIST = "GET_ORGANIZATION_LIST";
export const GET_ORGANIZATION_LIST_SUCCESS ="GET_ORGANIZATION_LIST_SUCCESS";
export const GET_ORGANIZATION_LIST_FAIL = "GET_ORGANIZATION_LIST_FAIL";
 ////////// Update organization

export const UPDATE_ORGANIZATION_LIST = "UPDATE_ORGANIZATION_LIST";
export const UPDATE_ORGANIZATION_LIST_SUCCESS = "UPDATE_ORGANIZATION_LIST_SUCCESS";
export const UPDATE_ORGANIZATION_LIST_FAIL = "UPDATE_ORGANIZATION_LIST_FAIL";
///////// delete organization

export const DELETE_ORGANIZATION_LIST = "DELETE_ORGANIZATION_LIST";
export const DELETE_ORGANIZATION_LIST_SUCCESS = "DELETE_ORGANIZATION_LIST_SUCCESS";
export const DELETE_ORGANIZATION_LIST_FAIL = "DELETE_ORGANIZATION_LIST_FAIL";
