/* SAMPLE COLLECTORS */
// get units
export const GET_UNITS_LIST = "GET_UNITS_LIST";
export const GET_UNITS_LIST_SUCCESS ="GET_UNITS_LIST_SUCCESS";
export const GET_UNITS_LIST_FAIL = "GET_UNITS_LIST_FAIL";
// Add units
export const ADD_NEW_UNITS = "ADD_NEW_UNITS";
export const ADD_NEW_UNITS_SUCCESS = "ADD_NEW_UNITS_SUCCESS";
export const ADD_NEW_UNITS_FAIL = "ADD_NEW_UNITS_FAIL";
//Update Units
export const UPDATE_UNITS = "UPDATE_UNITS";
export const UPDATE_UNITS_SUCCESS = "UPDATE_UNITS_SUCCESS";
export const UPDATE_UNITS_FAIL = "UPDATE_UNITS_FAIL";


/////UNITS added by analytes

export const GET_ANALYTESUNITS_LIST = "GET_ANALYTESUNITS_LIST_LIST";
export const GET_ANALYTESUNITS_LIST_SUCCESS ="GET_ANALYTESUNITS_LIST_SUCCESS";
export const GET_ANALYTESUNITS_LIST_FAIL = "GET_ANALYTESUNITS_LIST_FAIL";
// Add units
export const ADD_NEW_ANALYTESUNITS = "ADD_NEW_ANALYTESUNITS";
export const ADD_NEW_ANALYTESUNITS_SUCCESS = "ADD_NEW_ANALYTESUNITS_SUCCESS";
export const ADD_NEW_ANALYTESUNITS_FAIL = "ADD_NEW_ANALYTESUNITS_FAIL";
//Update Units
export const UPDATE_ANALYTESUNITS = "UPDATE_ANALYTESUNITS";
export const UPDATE_ANALYTESUNITS_SUCCESS = "UPDATE_ANALYTESUNITS_SUCCESS";
export const UPDATE_ANALYTESUNITS_FAIL = "UPDATE_ANALYTESUNITS_FAIL";


///get analytes associated with unit
export const GET_ANALYTESUNITS = "GET_ANALYTESUNITS_LIST";
export const GET_ANALYTESUNITS_SUCCESS ="GET_ANALYTESUNITS_SUCCESS";
export const GET_ANALYTESUNITS_FAIL = "GET_ANALYTESUNITS_FAIL";

///get analytes associated with method
export const GET_ANALYTESMETHODS = "GET_ANALYTESMETHODS_LIST";
export const GET_ANALYTESMETHODS_SUCCESS ="GET_ANALYTESMETHODS_SUCCESS";
export const GET_ANALYTESMETHODS_FAIL = "GET_ANALYTESMETHODS_FAIL";

///get analytes associated with INSTRUMENT
export const GET_ANALYTESINSTRUMENTS = "GET_ANALYTESINSTRUMENTS_LIST";
export const GET_ANALYTESINSTRUMENTS_SUCCESS ="GET_ANALYTESINSTRUMENTS_SUCCESS";
export const GET_ANALYTESINSTRUMENTS_FAIL = "GET_ANALYTESINSTRUMENTS_FAIL";

///get analytes associated with REAGENTS
export const GET_ANALYTESREAGENTS = "GET_ANALYTESREAGENTS_LIST";
export const GET_ANALYTESREAGENTS_SUCCESS ="GET_ANALYTESREAGENTS_SUCCESS";
export const GET_ANALYTESREAGENTS_FAIL = "GET_ANALYTESREAGENTS_FAIL";

///get instrumentsin instrument type
export const GET_INSTRUMENTSINTYPE = "GET_INSTRUMENTSINTYPE_LIST";
export const GET_INSTRUMENTSINTYPE_SUCCESS ="GET_INSTRUMENTSINTYPE_SUCCESS";
export const GET_INSTRUMENTSINTYPE_FAIL = "GET_INSTRUMENTSINTYPE_FAIL";

///get instrumentsin MANUFACTURER
export const GET_INSTRUMENTSINMANUFACTURER = "GET_INSTRUMENTSINMANUFACTURER_LIST";
export const GET_INSTRUMENTSINMANUFACTURER_SUCCESS ="GET_INSTRUMENTSINMANUFACTURER_SUCCESS";
export const GET_INSTRUMENTSINMANUFACTURER_FAIL = "GET_INSTRUMENTSINMANUFACTURER_FAIL";

///get reagents in MANUFACTURER
export const GET_REAGENTSINMANUFACTURER = "GET_REAGENTSINMANUFACTURER_LIST";
export const GET_REAGENTSINMANUFACTURER_SUCCESS ="GET_REAGENTSINMANUFACTURER_SUCCESS";
export const GET_REAGENTSINMANUFACTURER_FAIL = "GET_REAGENTSINMANUFACTURER_FAIL";