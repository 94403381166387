/* News */
export const GET_NEWS = "GET_NEWS";
export const GET_NEWS_SUCCESS =
  "GET_NEWS_SUCCESS";
export const GET_NEWS_FAIL = "GET_NEWS_FAIL";

///adding news
export const ADD_NEWS = "ADD_NEWS";
export const ADD_NEWS_SUCCESS = "ADD_NEWS_SUCCESS";
export const ADD_NEWS_FAIL = "ADD_NEWS_FAIL";
