// get Participants
export const GET_PARTICIPANT_LIST = "GET_PARTICIPANT_LIST";
export const GET_PARTICIPANT_LIST_SUCCESS ="GET_PARTICIPANT_LIST_SUCCESS";
export const GET_PARTICIPANT_LIST_FAIL = "GET_PARTICIPANT_LIST_FAIL";

// get Roun Participents
export const GET_PARTICIPANTROUND_LIST = "GET_PARTICIPANTROUND_LIST";
export const GET_PARTICIPANTROUND_LIST_SUCCESS ="GET_PARTICIPANTROUND_LIST_SUCCESS";
export const GET_PARTICIPANTROUND_LIST_FAIL = "GET_PARTICIPANTROUND_LIST_FAIL";

///// Get Labs added by Rounds

export const GET_ROUNDSLABS_LIST = "GET_ROUNDSLABS_LIST_LIST";
export const GET_ROUNDSLABS_LIST_SUCCESS ="GET_ROUNDSLABS_LIST_SUCCESS";
export const GET_ROUNDSLABS_LIST_FAIL = "GET_ROUNDSLABS_LIST_FAIL";
// Add Labs added by Rounds
export const ADD_NEW_ROUNDSLABS = "ADD_NEW_ROUNDSLABS";
export const ADD_NEW_ROUNDSLABS_SUCCESS = "ADD_NEW_ROUNDSLABS_SUCCESS";
export const ADD_NEW_ROUNDSLABS_FAIL = "ADD_NEW_ROUNDSLABS_FAIL";
//Update Labs added by Rounds
export const UPDATE_ROUNDSLABS = "UPDATE_ROUNDSLABS";
export const UPDATE_ROUNDSLABS_SUCCESS = "UPDATE_ROUNDSLABS_SUCCESS";
export const UPDATE_ROUNDSLABS_FAIL = "UPDATE_ROUNDSLABS_FAIL";

