// /* Scheme List */
export const SCHEMES_ANALYTES = "SCHEMES_ANALYTES";
export const SCHEMES_ANALYTES_SUCCESS = "SCHEMES_ANALYTES_SUCCESS";
export const SCHEMES_ANALYTES_FAIL = "SCHEMES_ANALYTES_FAIL";

export const POST_RESULT = "POST_RESULT";
export const POST_RESULT_SUCCESS = "POST_RESULT_SUCCESS";
export const POST_RESULT_FAIL = "POST_RESULT_FAIL";

export const GET_RESULT = "GET_RESULT";
export const GET_RESULT_SUCCESS = "GET_RESULT_SUCCESS";
export const GET_RESULT_FAIL = "GET_RESULT_FAIL";

export const GET_STATISTICS = "GET_STATISTICS";
export const GET_STATISTICS_SUCCESS = "GET_STATISTICS_SUCCESS";
export const GET_STATISTICS_FAIL = "GET_STATISTICS_FAIL";

export const POST_SERELOGY_VALUES = "POST_SERELOGY_VALUES";
export const POST_SERELOGY_VALUES_SUCCESS = "POST_SERELOGY_VALUES_SUCCESS";
export const POST_SERELOGY_VALUES_FAIL = "POST_SERELOGY_VALUES_FAIL";

export const GET_SERELOGY_VALUES = "GET_SERELOGY_VALUES";
export const GET_SERELOGY_VALUES_SUCCESS = "GET_SERELOGY_VALUES_SUCCESS";
export const GET_SERELOGY_VALUES_FAIL = "GET_SERELOGY_VALUES_FAIL";


