/* SAMPLE COLLECTORS */
// get DESIGNATION
export const GET_DESIGNATION_LIST = "GET_DESIGNATION_LIST";
export const GET_DESIGNATION_LIST_SUCCESS ="GET_DESIGNATION_LIST_SUCCESS";
export const GET_DESIGNATION_LIST_FAIL = "GET_DESIGNATION_LIST_FAIL";
// Add DESIGNATION
export const ADD_NEW_DESIGNATION = "ADD_NEW_DESIGNATION";
export const ADD_NEW_DESIGNATION_SUCCESS = "ADD_NEW_DESIGNATION_SUCCESS";
export const ADD_NEW_DESIGNATION_FAIL = "ADD_NEW_DESIGNATION_FAIL";
//Update DESIGNATION
export const UPDATE_DESIGNATION = "UPDATE_DESIGNATION";
export const UPDATE_DESIGNATION_SUCCESS = "UPDATE_DESIGNATION_SUCCESS";
export const UPDATE_DESIGNATION_FAIL = "UPDATE_DESIGNATION_FAIL";


